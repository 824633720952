import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiCast />,
    title: "Strategic Partnering",
    description:
      "We act as your strategic partner, connecting you with the top development agencies in the Balkans.",
  },
  {
    icon: <FiLayers />,
    title: "Web Development Expertise",
    description:
      "Explore the pinnacle of development through our network of skilled agencies.",
  },
  {
    icon: <FiUsers />,
    title: "Customized Development Solutions",
    description:
      "Our partner agencies excel in delivering tailored software solutions crafted for your unique business requirements",
  },
  {
    icon: <FiMonitor />,
    title: "Mobile App Development",
    description:
      "Elevate your digital presence with cutting-edge mobile app development solutions tailored to your specifications.",
  },
  {
    icon: <FiUsers />,
    title: "Quality Assurance & Testing",
    description:
      "Rely on our partner agencies for reliable and thoroughly tested solutions, ensuring optimal performance.",
  },
  {
    icon: <FiMonitor />,
    title: "Innovative Design & UX",
    description:
      "Collaborate with agencies that excel in innovative design and seamless user experiences, enhancing overall usability.",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href="/service-details">
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
